:root {
  /* 60% of all color */
  --primaryLight: hsl(26, 54%, 97%);
  --primaryDark: hsl(0, 0%, 26%);

  /* 30% of all color */
  --secondary0: hsl(0, 0%, 80%);

  /* 10% of all color */
  --accent0: hsl(224, 90%, 61%);
  --accent1: hsl(225, 77%, 54%);
  --accent2: hsl(224, 90%, 65%);
  --accent3: hsl(224, 90%, 68%);
  --accent4: hsl(223, 92%, 76%);

  --gray0: hsl(0, 0%, 97%);
  --gray1: hsl(0, 0%, 91%);
  --gray2: hsl(0, 0%, 79%);
  --gray3: hsl(0, 0%, 53%);
  --gray4: hsl(0, 0%, 34%);
  --gray5: hsl(0, 0%, 24%);
  --gray6: hsl(0, 0%, 12%);

  --borderColor: hsla(0, 0%, 0%, 0.302);
  --boxShadowColor: hsla(0, 0%, 0%, 0.3);

  --headerBar: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
}
html,
body {
  /* max-height: 100%; */
  max-width: 100%;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
* {
  font-family: "Avenir", sans-serif;
  -webkit-font-smoothing: antialiased;
  transition: background-color 3s ease;
  color: var(--primaryLight);
  margin:0;
  padding:0;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 1rem;
}
h6 {
  font-size: .75rem;
}
p {
  font-weight: 100;
}
a {
  font-weight: 100;
}
