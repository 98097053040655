.lottery {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  color: white;
  height: 100vh;
  width: 100vw;
}
.lotteryList {
  list-style: none;
  font-size: 1.5rem;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}
.lotterList li {
  color: white;
  font-size: 1.5rem;
}
.lottery body {
  letter-spacing: 9px;
  font-size: 60px;
  text-align: center;
  line-height: 100vh;
  font-family: Courier;
}

.lottery html {
  background: #000;
  color: #fff;
}

.lottery button {
  background: transparent;
  color: #fff;
  border: 1px solid;
  padding: 10px 20px;
  font-size: 20px;
  border-radius: 2px;
  cursor: pointer;
}
.lottery button:hover {
  background: #fff;
  color: #000;
  transition: all 0.5s ease-in-out;
}
.return {
  font-size: 1.5rem;
  cursor: pointer;
  padding-top: 3vh;
  padding-bottom: 5vh;
  padding-right: 2vw;
  position: absolute;
  right: 0;
}
