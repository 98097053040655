.main {
    padding-top:10vh;
    padding-bottom:2vh;
    min-height:70vh;
    display: flex;
    justify-content: center;
    background-color: black;
}
.section{
    width: auto;
    min-width: 60vw;
    max-width:100vw;
    padding:1vh 1vw 1vh 1vw;
    display: flex;
    flex-direction: column;
    background-color: black;
    border: 0px solid var(--borderColor);
    box-shadow: 0 3px 7px var(--boxShadowColor);
    border-radius:5px;
    
}
.loader{
    display: flex;
    justify-content: center;
    align-items: center;
}