.home {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  color: var(--primaryLight);
  padding: 2vh 0vw 2vh 5vw;
  height:100vh;
}
.homeHeader {
  max-width: 90vw;
  padding-bottom: 7.5vh;
}
.newsTable {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  row-gap: 1vh;
  max-width: 80vw;
  padding-right: 5vw;
  max-height: 50vh;
}
.newsTable ul{
  overflow-y: scroll;
}
.newsTable ul li {
  margin-bottom: 2vh;
}
