.hallfOfFame {
  color: var(--primaryLight);
  padding: 2vh 2vw 2vh 2vw;
}
.hofHeader {
  padding-bottom:10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hofTable {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap:5vh;
  /* max-width: 90vw; */
  color: var(--primaryLight);
}
.hofTable ul{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
h4{
  padding-bottom:3vh;
}
