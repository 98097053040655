.losers {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  color: white;
  height: 100vh;
  width: 90vw;
}
.loserListHolder {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.loserList {
  padding-top: 10vh;
  list-style: none;
  font-size: 1.5rem;
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  flex-direction: column;
  row-gap: 7vh;
  color: white;
  flex-wrap: nowrap;
  word-wrap: unset;
}
.loserListPair {
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  flex-direction: column;
  row-gap: 2vh;
  background-color: rgba(88, 88, 88, 0.457);
  /* filter:blur(80%); */
  backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 10px;
  width: auto;
  flex-wrap: nowrap;
  word-wrap: unset;
  font-size: 1.75vh;
}
.loserListPairChamp {
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  flex-direction: column;
  row-gap: 2vh;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  border-radius: 10px;
  padding: 10px;
  /* width: 15vw; */
  flex-wrap: nowrap;
  word-wrap: unset;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.lotterList li {
  color: white;
}
.losers body {
  letter-spacing: 9px;
  font-size: 60px;
  text-align: center;
  line-height: 100vh;
  font-family: Courier;
}

.losers html {
  background: #000;
  color: #fff;
}

.losers button {
  background: transparent;
  color: #fff;
  border: 1px solid;
  padding: 10px 20px;
  font-size: 20px;
  border-radius: 2px;
  cursor: pointer;
}
.losers button:hover {
  background: #fff;
  color: #000;
  transition: all 0.5s ease-in-out;
}
.return {
  font-size: 1.5rem;
  cursor: pointer;
  padding-top: 3vh;
  padding-bottom: 5vh;
  padding-right: 2vw;
  position: absolute;
  right: 0;
}
