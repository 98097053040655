.header {
    position: fixed;
    width: 100vw;
    height: 8vh;
    background-color: rgba(0, 0, 0, 0.6);
    /* filter:blur(80%); */
    backdrop-filter: blur(5px);
    color:var(--primaryLight);
    border: 0px solid var(--borderColor);
    box-shadow: 0 3px 7px var(--boxShadowColor);
    z-index: 1;
    overflow-x: scroll;
  }
  .headerContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height:100%;
    /* padding: 2.5vh 2vw 2vh 2vw; */
    padding-right:5vw;
  }
  .header ul {
    display: flex;
    list-style: none;
    padding:0;
  }
  .headerNav {
    /* max-width: 100%; */
    /* height: 100%; */
    /* align-items: center; */
    column-gap: 1rem;
  }
  .headerNav:hover {
  }
  .navItem {
    font-weight: 500;
    cursor: pointer;
    padding: 0vh 1.5vh 0vh 1.5vh;
    font-size:1rem;
  }
  /* everything for the progress bar */
  .progress {
    position: fixed;
    top: 7.3vh;
    z-index: 9999;
    width: 100vw;
    height: 0.75vh;
    background: hsla(0, 0%, 0%, 0);
  }
  .bar {
    display: block;
    width: 0vh;
    height: inherit;
    background: var(--headerBar);
    background-size: 400% 400%;
    animation: gradient 10.5s ease infinite;
  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
