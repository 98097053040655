.constitution {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  color: var(--primaryLight);
  padding: 2vh 3vw 2vh 5vw;
}
.ulHolder {
  flex-direction: column;
  flex-wrap: wrap;
  width: 90vw;
}
ul{
  padding-bottom:5vh;
}
h1{
  padding-bottom:5vh;
}
h3{
  padding-bottom:2vh;
}
h5 {
  padding-bottom:1.5vh;
  margin-left: 1.25vw;
}
h6 {
  padding-bottom:1.25vh;
  padding-left: 2vw;
}
